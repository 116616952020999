<!--
 * @Author: 候怀烨
 * @Date: 2020-11-25 18:45:22
 * @LastEditTime: 2020-11-25 19:01:54
 * @LastEditors: Please set LastEditors
 * @Description: 库管
 * @FilePath: \sd-vue-admin\src\views\project\treasury\requisition\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'Requisition',
  }
</script>

<style></style>
